import React from 'react';
import { useRouter } from 'next/router';
import { lighten } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const AnnouncementBanner = () => {
  const { locale, asPath } = useRouter();

  const announcementTexts = {
    'en-US': '2025 State of Mobile is Live!',
    'zh-CN': '我们已经收购了data.ai',
    ja: '当社はdata.aiを買収しました',
    ko: '센서타워, data.ai 인수',
  } as const;
  const linkTexts = {
    'en-US': 'Download Now',
    'zh-CN': '了解详情',
    ja: '詳細はこちら',
    ko: '자세히 알아보기',
  } as const;
  const queryStrings = {
    'en-US': '',
    'zh-CN':
      '?utm_source=website&utm_medium=website&utm_campaign=data-ai-joins-sensor-tower&utm_content=blog&utm_term=march18',
    ja: '?utm_source=website&utm_medium=website&utm_campaign=data-ai-joins-sensor-tower&utm_content=blog&utm_term=march18',
    ko: '?utm_source=website&utm_medium=website&utm_campaign=data-ai-joins-sensor-tower&utm_content=blog&utm_term=march18',
  } as const;
  const linkHrefs = {
    'en-US': '/state-of-mobile-2025',
    'zh-CN': '/zh-CN/blog/data-ai-joins-sensor-tower-cn',
    ja: '/ja/blog/data-ai-joins-sensor-tower-jp',
    ko: '/ko/blog/data-ai-joins-sensor-tower-kr',
  } as const;

  // @ts-expect-error - TS doesn't know that the locale is a string
  const announcementText = announcementTexts[locale] || announcementTexts['en-US'];
  // @ts-expect-error - TS doesn't know that the locale is a string
  const linkText = linkTexts[locale] || linkTexts['en-US'];
  // @ts-expect-error - TS doesn't know that the locale is a string
  const queryString = queryStrings[locale] || queryStrings['en-US'];
  // @ts-expect-error - TS doesn't know that the locale is a string
  const linkHref = (linkHrefs[locale] || linkHrefs['en-US']) + queryString;

  const currentPath = asPath.split('?')[0];

  // Don't show the banner on the page it links to
  if (currentPath === linkHref) {
    return null;
  }

  return (
    <Grid
      container
      direction={{ xs: 'column', md: 'row' }}
      bgcolor="blue.main"
      sx={[
        {
          minHeight: 64,
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 0, md: 2 },
        },
        (theme) => ({
          [theme.breakpoints.down('xs')]: { fontSize: 16 },
        }),
      ]}
    >
      <Grid item>
        <Typography variant="body1" color="blue.contrastText" sx={{ fontWeight: 600 }}>
          {announcementText}
        </Typography>
      </Grid>

      <Grid item>
        <a href={linkHref}>
          <Typography
            variant="body1"
            color="teal.main"
            sx={[
              { fontWeight: 600 },
              (theme) => ({
                '&:hover': {
                  color: lighten(theme.palette.teal.main, 0.4),
                },
              }),
            ]}
          >
            {linkText}
          </Typography>
        </a>
      </Grid>
    </Grid>
  );
};

export default AnnouncementBanner;
