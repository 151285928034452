import type { Theme } from '@mui/material/styles';

const lightVariant = (theme: Theme) => ({
  props: {
    variant: 'marketo-light',
  },
  style: {
    // Wrapper
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 33,
    padding: 50,

    // Loading spinner
    '.MuiCircularProgress-root': {
      color: `${theme.palette.secondary.contrastText} !important`,
    },

    // Intro text
    '& [class*="Text-root"] *': {
      color: `${theme.palette.secondary.contrastText} !important`,
    },

    // Form
    '.mktoForm': {
      // The default value set in the Form component is 602px for some reason
      minHeight: 300,
    },

    '.mktoFormRow': {
      // Field
      '.mktoFieldWrap': {
        border: 'none',
        backgroundColor: theme.palette.secondary.main,
        marginLeft: -10, // Negate offset introduced by mktoGutter

        // Input, textarea, and select inputs
        'input, textarea, select': {
          width: '100% !important',
          padding: '10px !important',
          color: `${theme.palette.secondary.contrastText} !important`,
          backgroundColor: `${theme.palette.secondary.main} !important`,
          fontSize: '16px !important',
          fontWeight: 400,
          lineHeight: `1.2 !important`,
          border: `1px solid ${theme.palette.secondary.contrastText}`,
          borderRadius: `10px !important`,
          boxShadow: 'none !important',
        },
        // Input and textarea placeholder
        'input::placeholder, textarea::placeholder': {
          color: `${theme.palette.secondary.contrastText} !important`,
          opacity: '0.8 !important',
        },
        '.mktoHtmlText': {
          width: '100% !important',
          p: {
            color: `${theme.palette.secondary.contrastText} !important`,
            textAlign: 'left',
            fontWeight: 400,
          },
        },
        '&:has(.mktoHtmlText)': {
          marginLeft: 0, // For some reason mktoGutter does not have any effect on this element
        },
      },
      // Make sure that the selector specificity is high enough to win with other styles...
      '.mktoFieldWrap[class*="-select"]': {
        // Select input selected option
        'select.mktoField': {
          color: `${theme.palette.secondary.contrastText} !important`,
          fontWeight: 400,
        },
        // Select input placeholder
        'select.mktoField:has(option[value=""]:checked)': {
          opacity: '0.8 !important',
        },
      },
      // Arrow for select input
      '.mktoFieldWrap[class*="-select"]:after': {
        borderTopColor: theme.palette.secondary.contrastText,
        top: 'calc(50% - 2px)',
      },
    },
    '.mktoButtonRow': {
      // Button
      marginTop: 24,
      '.mktoButtonWrap': {
        textAlign: 'right',
        button: {
          // Font color is different than in sections with teal.main background ¯\_(ツ)_/¯/
          color: `${theme.palette.primary.contrastText} !important`,
          borderRadius: '56px !important',
          backgroundColor: `${theme.palette.teal.main} !important`,
          ':hover': {
            backgroundColor: `${theme.palette.teal.dark} !important`,
          },
          height: 56,
          width: '100%',
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '18px !important',
          [theme.breakpoints.up('lg')]: {
            fontSize: '24px !important',
          },
        },
      },
    },
  },
});

// TODO: Make it the default variant
const darkVariant = (theme: Theme) => ({
  props: {
    variant: 'marketo-dark',
  },
  style: {
    // Wrapper
    backgroundColor: theme.palette.blue.main,
    borderRadius: 33,
    padding: 50,

    // Loading spinner
    '.MuiCircularProgress-root': {
      color: `${theme.palette.primary.contrastText} !important`,
    },

    // Intro text
    '& [class*="Text-root"] *': {
      color: `${theme.palette.primary.contrastText} !important`,
    },

    // Form
    '.mktoForm': {
      // The default value set in the Form component is 602px for some reason
      minHeight: 300,
    },

    '.mktoFormRow': {
      // Field
      '.mktoFieldWrap': {
        border: 'none',
        backgroundColor: `${theme.palette.blue.main} !important`,
        marginLeft: -10, // Negate offset introduced by mktoGutter

        // Text, textarea, and select inputs
        'input, textarea, select': {
          width: '100% !important',
          padding: '10px !important',
          color: `${theme.palette.primary.contrastText} !important`,
          backgroundColor: `${theme.palette.blue.main} !important`,
          fontSize: '16px !important',
          fontWeight: 400,
          lineHeight: `1.2 !important`,
          border: `1px solid ${theme.palette.teal.main}`,
          borderRadius: `10px !important`,
          boxShadow: 'none !important',
        },
        // Text input placeholder
        'input::placeholder, textarea::placeholder': {
          color: `${theme.palette.primary.contrastText} !important`,
          opacity: '0.8 !important',
        },
        '.mktoHtmlText': {
          width: '100% !important',
          p: {
            color: `${theme.palette.primary.contrastText} !important`,
            textAlign: 'left',
            fontWeight: 400,
          },
        },
        '&:has(.mktoHtmlText)': {
          marginLeft: 0, // For some reason mktoGutter does not have any effect on this element
        },
      },
      // Make sure that the selector specificity is high enough to win with other styles...
      '.mktoFieldWrap[class*="-select"]': {
        // Select input selected option
        'select.mktoField': {
          color: `${theme.palette.primary.contrastText} !important`,
          fontWeight: 400,
        },
        // Select input placeholder
        'select.mktoField:has(option[value=""]:checked)': {
          opacity: '0.8 !important',
        },
      },
      // Arrow for select input
      '.mktoFieldWrap[class*="-select"]:after': {
        borderTopColor: theme.palette.primary.contrastText,
        top: 'calc(50% - 2px)',
      },
    },
    '.mktoButtonRow': {
      // Button
      marginTop: 24,
      '.mktoButtonWrap': {
        textAlign: 'right',
        button: {
          // Font color is different than in sections with teal.main background ¯\_(ツ)_/¯/
          color: `${theme.palette.primary.contrastText} !important`,
          borderRadius: '56px !important',
          backgroundColor: `${theme.palette.teal.main} !important`,
          ':hover': {
            backgroundColor: `${theme.palette.teal.dark} !important`,
          },
          height: 56,
          width: '100%',
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '18px !important',
          [theme.breakpoints.up('lg')]: {
            fontSize: '24px !important',
          },
        },
      },
    },
  },
});

// The same look as the dark variant, but the component used is FormInfogramEmbed rather than FormMarketoEmbed.
const infogramVariant = (theme: Theme) => ({
  props: { variant: 'marketo-infogram' },
  style: darkVariant(theme).style,
});

// TODO: Extract layout styles into baseStyles and merge with the variant styles
// All variants should have the same layout, but color schemes should be different
const variants = [lightVariant, darkVariant, infogramVariant];

const createFormMarketoEmbedVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createFormMarketoEmbedVariants;
