import { Theme } from '@mui/material';
import { titleTopLineStyle } from '../custom';

export const defaultVariant = (_: Theme) => ({
  props: {
    variant: 'default',
  },
  style: {},
});

export const onePerRowVariant = (_theme: Theme) => ({
  props: {
    variant: 'one-per-row',
  },
  style: {
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      '& > [class*="Section-gridItem"]': {
        flex: '0 0 100%',
      },
    },
  },
});

export const mediaVariant = (theme: Theme) => ({
  props: {
    variant: 'media',
  },
  style: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6, 4),
    '& > [class*="Section-gridContainer"]': {
      '& > [class*="Section-gridItem"]': {
        flex: '0 100%',
      },
    },
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
    h2: {
      marginBottom: theme.spacing(2),
    },
    h3: {
      marginBottom: theme.spacing(2),
      fontWeight: 200,
      '& b': {
        color: theme.palette.text.primary,
        fontWeight: 400,
      },
    },
    p: {
      ...theme.typography.body1,
      color: theme.palette.secondary.contrastText,
    },
  },
});

export const standardVariant = (theme: Theme) => ({
  props: {
    variant: 'standard',
  },
  style: {
    padding: theme.spacing(5, 0),
    // Remove bottom border from last child that is added in the base card styles
    '& [class*=Section-gridItem]:last-child .MuiCard-root': {
      [theme.breakpoints.down('md')]: {
        borderBottom: 'none',
      },
    },
    '& > [class*="Section-gridContainer"]': {
      '& > [class*="Section-gridItem"]': {
        flex: '0 100%',
      },
    },
    '& [class*="Section-introText"]': {
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    '& [class*="Text-root"]': {
      position: 'relative',
      '& h2': {
        paddingBottom: 0,
        color: theme.palette.secondary.contrastText,
        '& b, & strong': {
          fontWeight: 700,
        },
        '& + h2': {
          '& + hr': {
            ...titleTopLineStyle(theme),
          },
        },
      },
    },
  },
});

export const featuredVariant = (theme: Theme) => ({
  props: {
    variant: 'highlight',
  },
  style: {
    margin: 0,
    padding: theme.spacing(0, 9),
    borderRadius: 10,
    overflow: 'hidden',
    background: 'radial-gradient(119.15% 119.15% at 56.52% 48.94%, #187E71 20.15%, #0B423C 100%)',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4, 0),
      // Breakout of container and go full bleed
      width: 'calc(100% + 64px)',
      'margin-left': '-32px',
      'border-radius': '0px',
    },
    '& [class*="Section-gridContainer"]': {
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column-reverse',
      },
      '& > [class*="Section-gridItem"]': {
        '& img': {
          width: 'auto',
          height: '100%',
        },
        [theme.breakpoints.down('lg')]: {
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            img: {
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'bottom',
            },
          },
        },
        [theme.breakpoints.up('lg')]: {
          '&:first-child': {
            width: '50%',
          },
          '&:last-child': {
            width: '50%',
            position: 'relative',
          },
          '& img': {
            position: 'absolute',
            right: 0,
            // Calc to take into account the padding
            // Space between images is 72px,
            width: 'calc(100% - 72px)',
            height: 'auto',
          },
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
          '& img': {
            '&:first-child': {
              display: 'block !important',
            },
            '&:last-child': {
              display: 'none !important',
            },
          },
        },
      },
    },

    '& [class*="Text-root"]': {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      '& h2': {
        color: theme.palette.secondary.main,
        paddingBottom: theme.spacing(3),
        fontWeight: 900,
      },
      '& h3': {
        paddingBottom: 0,
        color: theme.palette.secondary.main,
        '& + h3': {
          fontWeight: 200,
        },
      },
      '& p': {
        paddingBottom: theme.spacing(2),
        position: 'relative',
        marginTop: theme.spacing(5),
        color: theme.palette.secondary.main,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: `-${theme.spacing(2)}`,
          left: 0,
          backgroundColor: theme.palette.primary.contrastText,
          width: 12,
          height: 1,
          [theme.breakpoints.up('lg')]: {
            width: 16,
          },
        },
      },
    },
  },
});

export const newsletterVariant = (theme: Theme) => ({
  props: {
    variant: 'newsletter',
  },
  style: {
    padding: theme.spacing(4, 0),
    '& [class*="Section-gridContainer"]': {
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& [class*="MuiGrid-root"]': {
          width: '100%',
          flexBasis: 'unset',
          flexGrow: 'unset',
        },
      },
      '& p': {
        maxWidth: '86%',
        fontSize: 13,
        fontWeight: 400,
        [theme.breakpoints.up('lg')]: {
          maxWidth: '100%',
          fontSize: 14,
        },
      },
    },
  },
});

export const gatedResourceFormVariant = (theme: Theme) => ({
  props: {
    variant: 'gated-resource-form',
  },
  style: {
    minHeight: '75vh',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    backgroundColor: theme.palette.background.light,

    [theme.breakpoints.down('md')]: {
      '& [class*="Section-gridContainer"]': {
        flexDirection: 'column',

        '& > [class*="Section-gridItem"]': {
          flex: '0 100%',
        },
      },
    },

    '& .MuiTypography-h1': {
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '40px',
    },

    '& .MuiTypography-body1': {
      color: theme.palette.grey[900],
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },

    '& [class*="Text-root"] [class*="Media-root"]': {
      width: '100%',
      margin: theme.spacing(3, 0, 5),
      borderRadius: 20,
    },

    // Marketo form
    '& [class*="FormMarketoEmbed-formWrapper"]': {
      boxShadow: '0 5px 25px rgba(0, 0, 0, 0.05)',
    },

    '& [class*="FormMarketoEmbed-form"]': {
      minHeight: 200,
      margin: '0',
      padding: `${theme.spacing(0, 2)} !important`,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
      color: `${theme.palette.grey[900]} !important`,

      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing(45),
      },

      '& [class*="Text-root"]': {
        padding: theme.spacing(5, 3, 0),

        '& [class*="MuiTypography-h3"]': {
          paddingBottom: theme.spacing(3),
          color: theme.palette.teal.light,
          fontWeight: 400,
          lineHeight: 1,
        },
      },

      '& [class*="MuiCircularProgress-root"]': {
        color: theme.palette.teal.light,
      },

      '& [class*="mktoOffset"]': {
        width: '0 !important',
      },

      '& [class*="mktoFieldWrap"]': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: '1px !important',
      },

      '& [class*="mktoFormRow"]': {
        marginBottom: '0 !important',
        padding: `${theme.spacing(0, 1)} !important`,
      },

      '& [class*="mktoLabel"]': {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: 'auto !important',
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        color: theme.palette.grey[900],
        fontSize: '18px !important',
        fontWeight: '300 !important',
        lineHeight: 1,
      },

      '& input[class*="mktoField"]': {
        minHeight: 'auto',
        padding: `${theme.spacing(1)} !important`,
        boxShadow: 'none',
        borderColor: theme.palette.grey[200],
        borderRadius: theme.spacing(1),
        fontSize: '18px !important',
        lineHeight: '18px',

        '&[class*="mktoInvalid"]': {
          borderColor: theme.palette.error.main,
        },
      },

      '& [class*="mktoErrorMsg"]': {
        fontSize: '14px !important',
      },

      '& [class*="mktoButtonWrap"]': {
        margin: `${theme.spacing(2, 1, 3)} !important`,

        '& button': {
          height: 'auto !important',
          padding: `${theme.spacing(1.8)} !important`,
          backgroundColor: `${theme.palette.teal.light} !important`,
          color: `${theme.palette.primary.contrastText} !important`,
          fontSize: '14px !important',
          fontWeight: 600,
          lineHeight: '16px !important',
          letterSpacing: '2.7px',
          textShadow: 'none !important',

          '&:hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
          },
        },
      },
    },

    '& [class*="FormMarketoEmbed-successWrapper"]': {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),

      '& .MuiTypography-body1': {
        fontSize: 18,
        fontWeight: 300,
      },
    },
  },
});

/* Wrapper variants */

export const wrapperVariant = () => ({
  props: {
    variant: 'wrapper',
  },
  style: {
    '& > [class*="Section-contentContainer"]': {
      padding: 0,
      maxWidth: '100%',
    },
  },
});

export const quoteSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Quote_default_section-wrapper',
  },
  style: {
    backgroundColor: theme.palette.background.dark,
  },
});

export const collectionLogosSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Collection_logos_section-wrapper',
  },
  style: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 0),
    },
  },
});

export const collectionIconDarkThreeSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Collection_icon-dark-three_section-wrapper',
  },
  style: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
    backgroundColor: theme.palette.product.main,
  },
});

export const collectionIconDarkFourSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Collection_icon-dark-four_section-wrapper',
  },
  style: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
    backgroundColor: theme.palette.product.main,
  },
});

export const cardMediaLeftTextRightSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Card_media-left-text-right_section-wrapper',
  },
  style: {
    margin: theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
});

export const cardMediaRightTextLeftSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Card_media-right-text-left_section-wrapper',
  },
  style: {
    margin: theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
});

export const textDefaultSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Text_default_section-wrapper',
  },
  style: {
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 10),
    },
  },
});

export const collectionThreePerRowSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Collection_three-per-row_section-wrapper',
  },
  style: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
});

export const ModuleIntegrationLeverSectionWrapperVariant = (theme: Theme) => ({
  props: {
    variant: 'Moduleintegration_lever_section-wrapper',
  },
  style: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
});

const variants = [
  defaultVariant,
  mediaVariant,
  standardVariant,
  featuredVariant,
  newsletterVariant,
  onePerRowVariant,
  gatedResourceFormVariant,
  quoteSectionWrapperVariant,
  collectionLogosSectionWrapperVariant,
  collectionIconDarkThreeSectionWrapperVariant,
  collectionIconDarkFourSectionWrapperVariant,
  textDefaultSectionWrapperVariant,
  collectionThreePerRowSectionWrapperVariant,
  cardMediaLeftTextRightSectionWrapperVariant,
  cardMediaRightTextLeftSectionWrapperVariant,
  ModuleIntegrationLeverSectionWrapperVariant,
];

const createSectionVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createSectionVariants;
