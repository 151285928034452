import React from 'react';
import chunk from 'lodash/chunk';
import {
  Box,
  Button,
  Popper as MuiPopper,
  Grow,
  Paper,
  ClickAwayListener,
  Stack,
  Divider,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Typography,
  AccordionSummaryProps,
  List
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import Link from '../Link';
import { NavigationItemLinkProps } from '../NavigationItemLink';
import { sidekick } from '../../utils/sidekick';
import ConditionalWrapper from '../../base-components/ConditionalWrapper';

export interface NavigationItemProps extends NavigationItemLinkProps {}

const getChunk = (chunk: NavigationItemProps[]) => chunk.map((item) => <ContentModule key={item.id} {...item} />);

export const NavigationItemSection = ({
  id,
  text,
  href,
  subNavigation,
  variant,
  sidekickLookup
}: NavigationItemProps) => {
  const [open, setOpen] = React.useState(false);
  const [isHover, setHover] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const items = React.useMemo(() => {
    if (
      subNavigation &&
      subNavigation[0] &&
      (subNavigation[0].variant === 'link' || subNavigation[0].variant === 'login')
    ) {
      return [subNavigation];
    }
    return chunk(subNavigation, 1);
  }, [subNavigation]);

  const hasSubGroup = items?.length && items[0]?.[0]?.variant === 'subgroup';
  const hasLinkVariantChildren = React.useMemo(
    () => Boolean(subNavigation?.some((item) => item.variant === 'link')),
    [subNavigation]
  );

  if (!subNavigation)
    return (
      <ErrorBoundary>
        <Root {...sidekick(sidekickLookup)} data-testid="NavigationItem">
          <Button
            sx={{
              display: { xs: 'none', lg: 'block' },
              textAlign: { lg: variant === 'button' ? 'center' : 'inherit' }
            }}
            variant={variant === 'button' ? 'contained' : 'nav'}
            component={Link as any}
            href={href}
            noLinkStyle
            name="link1"
            data-testid="NavigationItem-button"
          >
            {text}
          </Button>

          <Accordion sx={{ display: { xs: 'block', lg: 'none' } }} expanded={false} elevation={0}>
            <AccordionSummary id={id} component={Link} href={href} noLinkStyle>
              <Typography data-testid="NavigationItem-accordionSummary">{text}</Typography>
            </AccordionSummary>
          </Accordion>
          <AccordionDivider sx={{ display: { xs: 'block', lg: 'none' } }} />
        </Root>
      </ErrorBoundary>
    );

  return (
    <ErrorBoundary>
      <Root {...sidekick(sidekickLookup)} data-testid="NavigationItem">
        <Button
          sx={{ display: { xs: 'none', lg: 'block' } }}
          variant={variant === 'button' ? 'contained' : 'nav'}
          disabled={open}
          ref={anchorRef}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          data-testid="NavigationItem-button"
        >
          {text}
        </Button>

        <Accordion disableGutters sx={{ display: { xs: 'block', lg: 'none' } }} elevation={0}>
          <AccordionSummary id={id} expandIcon={<ArrowForwardIosSharp />}>
            <Typography data-testid="NavigationItem-accordionSummary">{text}</Typography>
          </AccordionSummary>
          <AccordionDetails data-testid="NavigationItem-accordionDetails">
            <ConditionalWrapper condition={hasLinkVariantChildren} wrapper={(children) => <List>{children}</List>}>
              {subNavigation?.map((subNav) => <ContentModule key={subNav.id} {...subNav} />)}
            </ConditionalWrapper>
          </AccordionDetails>
        </Accordion>

        <AccordionDivider sx={{ display: { xs: 'block', lg: 'none' } }} />

        <Popper
          open={open || isHover}
          anchorEl={anchorRef.current}
          placement="bottom"
          transition
          disablePortal
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onMouseDown={() => setOpen(false)}
          modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <div>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
                    <Paper sx={{ paddingTop: 4, paddingBottom: 2, borderRadius: 4 }} elevation={24}>
                      <PrimaryStack
                        direction={hasSubGroup ? 'column' : 'row'}
                        divider={!hasSubGroup && <Divider orientation="vertical" flexItem />}
                      >
                        {items.map((chunk: NavigationItemProps[]) => (
                          <SecondaryStack
                            key={`chunk-${chunk[0].id}`}
                            divider={items.length === 1 ? null : <Divider orientation="horizontal" variant="middle" />}
                          >
                            {getChunk(chunk)}
                          </SecondaryStack>
                        ))}
                      </PrimaryStack>
                    </Paper>
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(Box, {
  name: 'NavigationItemSection',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(() => ({}));

const PrimaryStack = styled(Stack, {
  name: 'NavigationItemSection',
  slot: 'PrimaryStack',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(({ theme }) => ({
  '& .MuiDivider-root': {
    borderColor: theme.palette.text.primary,
    opacity: 0.5,
    margin: theme.spacing(2, 5)
  },
  '& .MuiDivider-vertical': {
    margin: theme.spacing(1, 0, 2)
  }
}));

const SecondaryStack = styled(Stack, {
  name: 'NavigationItemSection',
  slot: 'SecondaryStack',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}));

const AccordionSummary = styled(MuiAccordionSummary, {
  name: 'NavigationItemSection',
  slot: 'AccordionSummary',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<AccordionSummaryProps<React.ElementType>>(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    fontWeight: 600
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.contrastText
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  }
}));

const AccordionDivider = styled(Divider, {
  name: 'NavigationItemSection',
  slot: 'AccordionDivider',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    ...(styles.root as any)
  })
})<{ variant?: string }>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginRight: theme.spacing(2.5),
  opacity: 0.5,

  [theme.breakpoints.down('lg')]: {
    display: 'block !important'
  }
}));

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow'
})(({ theme }) => ({
  zIndex: 1100,
  '& > div': {
    position: 'relative'
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      paddingTop: theme.spacing(2)
    },
    '& .MuiPopper-arrow': {
      top: 2,
      left: 0,
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    }
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: 2
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    }
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: 2
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    }
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: 2
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  }
}));

const Arrow = styled('div')(() => ({
  position: 'absolute',
  fontSize: 14,
  width: '6em',
  height: '6em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderColor: 'black'
  }
}));

export default NavigationItemSection;
